.timelineCompact {
  padding: $spacing-04 $spacing-01 0;
  overflow-x: hidden;
  position: absolute;

  z-index: 9;
  width: 83px;
  right: 0px;
  top: 50px;
  height: 100%;
  overflow: auto;
  opacity: 0;
  top: 0;
  display: none;

  h2 {
    text-align: center;
    @include header-01;
  }
  @include breakpoint('large-') {
    width: 83px;
  }
}

.yearSection {
  padding: 0 0 $spacing-03;
}

.dayTrigger {
  @include buttonReset;
  @include ui-yearTitle;
  text-align: center;
  width: 100%;
  display: block;
  padding: $spacing-03 0;
  border-bottom: 1px solid #cccccc;
  position: relative;

  &:hover {
    color: $color-blue;
  }

  &.is-active {
    color: $color-blue;
  }

  &.is-active:after {
    content: '';
    left: 0;
    position: absolute;
    height: 2px;
    bottom: -1px;
    width: 100%;
    background: $color-blue;
    z-index: 2;
  }
}
