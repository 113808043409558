.hotspot {
  position: absolute;
  transition: background 0.4s;
  &.is-active {
    .infoBubble {
      background: $color-orange;
    }
  }
}
.infoBubble {
  @include buttonReset();
  cursor: pointer;
  transition: 0.2s transform, 0.2s background;
  height: 32px;
  width: 32px;
  background: $color-blue;
  display: block;
  border-radius: 100%;

  &.is-active {
    background: $color-orange;
  }

  &:hover {
    background: $color-orange;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    border: 3px solid $color-white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.infoBubble--custom {
  width: 58px;
  height: 58px;
  border-radius: 100%;
  overflow: hidden;
  transition: 0.2s opacity;

  img {
    width: 100%;
  }

  &:before {
    display: none;
  }

  &:hover {
    opacity: 0.9;
  }
}

.tippy-content {
  padding: 0;
}
.tippy-box {
  background: none;
}
.tippy-arrow {
  display: none;
}
