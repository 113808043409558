.mapComponent__detail {
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 4;
  padding: 0;

  @include breakpoint('medium-') {
    right: 24px;
    left: auto;
  }

  button {
    @include buttonReset;
    @include ui-detailMap;
    border-radius: 2px;
    position: relative;
    background: $color-white;
    min-height: 100px;
    width: 100px;

    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    border: 3px solid #004899;
    padding: 7px 10px;
    overflow: hidden;
    transition: 0.3s opacity;
    &:hover {
      opacity: 0.8;
    }
    img {
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.mapComponent__detail-inner {
  position: relative;
  z-index: 2;
}
