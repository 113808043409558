.timelineView {
  padding-bottom: $spacing-04;
  .monthCard {
    border-bottom: 1px solid $color-border;
    position: relative;

    &.is-active {
      h3 {
        color: $color-blue;
      }

      &:before {
        content: '';
        left: 0;
        position: absolute;
        height: 2px;
        bottom: -1px;
        width: 100%;
        background: $color-blue;
        z-index: 2;
      }
    }

    button {
      @include buttonReset;
      width: 100%;
      display: block;
      text-align: left;
      padding: $spacing-04 0;
      outline-width: 1px !important;
      transition: 0.3s color;
      &:hover,
      &:focus {
        color: $color-blue;
      }
    }
    h3 {
      @include header-04;
      text-transform: none;
    }
    p {
      @include ui-uitext;
      margin-top: $spacing-07;
    }
  }
}

.yearSelect__value {
  @include buttonReset;
  font-family: 'Mercury Display';
  font-size: 28px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  width: 150px;
  border: 1px solid black;
  padding: 0 12px;
  height: 52px;
}

.yearSelect {
  position: relative;

  width: 150px;

  &.is-open {
    .yearSelect__value {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.yearSelect__outer {
  position: sticky;
  top: 0;
  z-index: 3;
  background: #f5f2ed;
  width: 100%;
  padding: 24px 0px 24px;
}

.yearSelect__list {
  z-index: 7;

  position: absolute;
  width: 150px;
  border: 1px solid black;

  font-family: 'Mercury Display';
  font-size: 28px;
  justify-content: space-between;
  align-items: center;
  letter-spacing: -0.01em;
  width: 150px;
  border: 1px solid black;
  background: white;
  button {
    @include buttonReset;
    text-align: left;
    width: 100%;
    font-family: 'Mercury Display';
    font-size: 28px;
    justify-content: space-between;
    align-items: center;
    letter-spacing: -0.01em;

    display: block;
    height: 52px;
    padding: 0px;
    padding-left: 12px;
    &:hover {
      background: #eee;
    }
    border-bottom: 1px solid #ccc;
    &:last-child {
      border: none;
    }
  }
}

.timelineView {
  .monthCard:first-child {
    button {
      padding: 0;
    }
  }
}
