@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loadingApp {
  animation: flickerAnimation 3s infinite;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  font-size: 24px;
  color: #b3b3b3;
  z-index: 999999;
}

.loadScreen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #f1f3f2;
  // background: red;
  z-index: 999998;
}
