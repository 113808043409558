.audioPlayer {
  display: flex;
  align-items: center;
}

.audioPlayer__trigger {
  @include buttonReset;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.audioPlayer__loading {
  margin-left: 4px;
}
