.panZoomModule__close {
  top: 12px;
  right: 12px;
  position: absolute;
  z-index: 9;
}

// .transformArea {
//   height: calc(100% - 150px);
//   top: 0;
//   left: 0;
//   position: absolute;
//   width: 100%;
//   @include breakpoint('medium-') {
//     height: 100%;
//   }
// }

.panZoomModule {
  width: 100%;

  .controls {
    position: absolute;
    right: 12px;
    bottom: 12px;
    height: 104px;
    width: 52px;
    z-index: 2;
    .iconButton {
      margin-top: 2px;
    }
  }

  .panZoomModule--info {
    @include breakpoint('large+') {
      .zoomArea__outer {
        height: calc(100vh - 150px);
      }
    }
  }

  .zoomArea__outer {
    background: #eee;
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  .zoomArea {
    img {
      position: absolute;
    }
  }

  .type--square,
  .type--landscape {
    img {
      width: auto;
      max-width: 100vw;
    }
  }

  .type--protrait {
    img {
      height: 100%;
    }
  }

  .panZoomModule__info {
    height: 150px;
    position: absolute;
    bottom: 0;
    background: $color-navy;
    width: 100%;
    left: 0;

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .panZoomModule__attr {
    @extend %container;
    padding: 20px 0;
    color: $color-white;
    @include ui-04;

    p {
      margin-top: 21px;
      &:first-child {
        margin-top: 0;
      }
      @include width(7, 'xlarge');
      @include breakpoint('large') {
        @include width(7, 'large');
      }
    }
  }
}

// .panZoomModule__close {
//   top: 12px;
//   right: 12px;
//   position: absolute;
//   z-index: 9;
// }

// .transformArea {
//   height: calc(100% - 150px);
//   top: 0;
//   left: 0;
//   position: absolute;
//   width: 100%;
//   @include breakpoint('medium-') {
//     height: 100%;
//   }
// }

// .panZoomModule__info {
//   height: 150px;
//   position: absolute;
//   bottom: 0;
//   background: $color-navy;
//   width: 100%;
//   left: 0;

//   @include breakpoint('medium-') {
//     display: none;
//   }
// }

// .panZoomModule__attr {
//   @extend %container;
//   padding: 20px 0;
//   color: $color-white;
//   @include ui-04;

//   p {
//     margin-top: 21px;
//     &:first-child {
//       margin-top: 0;
//     }
//     @include width(7, 'xlarge');
//     @include breakpoint('large') {
//       @include width(7, 'large');
//     }
//   }
// }

// .zoomModal {
//   .react-transform-component {
//     position: absolute;
//     background: #eee;
//     width: 100% !important;
//     height: 100% !important;
//   }

//   .react-transform-element {
//     position: absolute !important;
//   }
// }

// .zoomNode {
//   position: absolute;
//   img {
//     display: block;
//     width: auto;
//   }
// }

// .zoomArea {
//   top: 0;
//   left: 0;
//   height: calc(100% - 150px);
//   position: absolute;
//   width: 100%;
//   @include breakpoint('medium-') {
//     height: 100%;
//   }

//   .controls {
//     bottom: 12px;
//     right: 12px;
//     position: absolute;
//     z-index: 9;
//     div {
//       margin-top: 2px;
//     }
//   }
// }
