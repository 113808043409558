.infoPopup {
  @include ui-modaltext;
  height: auto;
  color: $color-white;
  width: 320px;
  padding: 16px 18px;
  background: $color-navy;

  .button {
    height: auto;
    margin-top: 20px;
    background: $color-navy;
    padding: 0;
    padding-left: 0;
    color: $color-white;
    transition: 0.4s all;

    &:hover {
      @include hover-supported() {
        color: $color-orange;
        background: $color-navy;
        transform: translateX(10px);
      }
    }

    svg {
      position: relative;
      top: -1px;
      margin-left: 18px;
    }

    path {
      fill: $color-orange;
    }
  }
}

.infoPopup--glossary {
  p:first-of-type {
    margin-top: 0;
  }
  .button {
    margin-top: 20px;
    border-radius: 2px;
    padding: 16px;
    color: $color-blue;
    background: $color-white;

    display: inline-flex;

    &:hover {
      @include hover-supported() {
        color: $color-blue;
        background: $color-light-button-hover;
        transform: translateX(0px);
      }
    }

    svg {
      position: relative;
      margin-left: 18px;
    }

    path {
      fill: $color-blue;
    }
  }
}
.panImage {
  visibility: hidden;
}
