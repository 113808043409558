.quote {
  blockquote {
    position: relative;
    margin-left: 48px;
  }
  blockquote p {
    font-family: 'Mercury Display';
    font-size: 28px;
    line-height: 115%;
    letter-spacing: -0.01em;
    font-weight: 700;
    font-style: oblique;
  }

  cite {
    display: block;
    margin-top: 12px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125%;
  }
}

.quote__icon {
  left: -48px;
  position: absolute;
  top: 8px;
}
