// overlay
@mixin header-01 {
  font-family: 'Mercury Display';
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.01em;
}

@mixin header-02 {
  font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 110%;
}

@mixin header-03 {
  font-family: 'Mercury Display';
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  /* or 40px */

  letter-spacing: -0.01em;
}
//option select
@mixin header-04 {
  font-family: 'Mercury Display';
  text-transform: uppercase;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */
  letter-spacing: 0.04em;
}

@mixin header-05 {
  font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 125%;
}

@mixin header-06 {
  letter-spacing: 0.05em;

  font-family: 'Mercury Display';
  font-size: 16px;
  line-height: 110%;
}

@mixin header-07 {
  font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 150%;
  /* or 31px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
}

@mixin header-08 {
  font-family: 'Mercury Display';
  font-size: 19px;
  line-height: 110%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
