html,
body,
#root {
  height: 100%;
}
.container,
%container {
  margin-right: auto;
  margin-left: auto;

  @each $name, $point in $breakpoints {
    @include breakpoint('#{$name}') {
      @if (map-get($main-col-widths, $name) == 'fluid') {
        width: calc(100vw - #{map-get($outer-gutters, $name) * 2});
      } @else {
        width: map-get($main-col-widths, $name);
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.App {
  background: #f1f3f2;
}

.page {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: #f1f3f2;
  overflow: hidden;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
}

.panel--border {
  border: 1px solid black;
}
