.infoButton {
  @include buttonReset;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  font-size: 18px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  background: none;
  justify-content: center;
  color: white;
}

.infoButton--dark {
  border-color: #6e6e6e;
  color: #6e6e6e;
}
