@keyframes move {
  0% {
    transform-origin: bottom left;
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.introImage {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  animation: move 30s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.introImage__mobile {
  display: none;
  @include breakpoint('medium-') {
    display: block;
  }
}

.introImage__desktop {
  @include breakpoint('medium-') {
    display: none;
  }
}

.page--intro {
  position: relative;
  display: block;
  height: 100%;

  .page__wrapper {
    height: 100%;
  }

  .panel {
    min-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    height: 100%;
    padding: 20px;
    overflow: auto;

    p {
      @include header-01;
      color: $color-white;
      margin: 0 auto;
      @include width(8);
      text-align: center;
      @include breakpoint('medium-') {
        width: 100%;
        padding: 0 30px;
        line-height: 40px;
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.01em;
      }
    }

    .button {
      margin: 0 auto;
      margin-top: 49px;
      margin-bottom: 30px;
    }
  }

  .panel__inner {
    border: 1px solid white;
    padding: 10px;
    min-height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: $color-white;
  }

  .page__wrapper {
    position: relative;
    z-index: 3;
  }

  .panel__inner {
    display: flex;
    flex-direction: column;
  }
}

.page--select {
  position: relative;
  .page__wrapper {
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    @include breakpoint('medium-') {
      padding-top: 60px;
    }
  }

  .panel {
    @extend %container;
    height: calc(100vh - 80px);
    position: relative;
    z-index: 4;
    @include breakpoint('medium-') {
      height: 100%;
    }
  }

  .panel__inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .panel {
    border: 1px solid black; // background: pink;
    @include breakpoint('medium-') {
      width: 100%;
      margin: 0;
      border: none;
    }
  }

  .panel__inner {
    justify-content: flex-start;
  }
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
