.mapControls {
  background: #f5f2ed;
  transition: transform 0.4s;
}

.mapControls__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mapControls__inner {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
}

.mapControls__header {
  padding: $spacing-01 $spacing-06;
  background: $color-blue;
  color: $color-white;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;

  @include breakpoint('large-') {
    padding: $spacing-01 0;
    padding-right: $spacing-06;
  }
  &:before {
    content: '';
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 100%;
    background: $color-blue;
  }

  h1 {
    @include header-08;
  }
}

.mapControls__expandTrigger {
  @include buttonReset;
  margin-left: auto;
  padding: 0;
  svg {
    transform: rotate(180deg);
  }
  path {
    fill: $color-white;
  }

  &:focus {
    path {
      fill: #e5e5e5;
    }
  }

  &:hover {
    @include hover-supported {
      path {
        fill: #e5e5e5;
      }
    }
  }
}

.mapControls__content {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .scroller {
    position: relative;
    padding: 0 $spacing-06;
    height: 100%;
    overflow: auto;
    @include breakpoint('large-') {
      padding: 0;
      padding-right: $spacing-06;
    }
  }
}

.mapControls__tabs {
  padding: 0 $spacing-04;
  @include breakpoint('large-') {
    padding: 0;
    padding-right: $spacing-04;
  }

  .inner {
    display: flex;
    border-bottom: 1px solid $color-border;
    width: 100%;
  }
  .mapControl__tab {
    @include buttonReset;
    @include ui-04;
    padding: 0 $spacing-07;
    padding-bottom: $spacing-01;
    margin-top: $spacing-04;
    color: $color-soi-grey;
    &:focus {
      color: $color-blue;
    }

    &:hover {
      @include hover-supported {
        color: $color-blue;
      }
    }

    &.is-active {
      color: $color-blue;
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: $color-blue;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .moreInfo {
    cursor: pointer;
    display: flex;
    margin-left: auto;
    transition: 0.3s opacity;
    position: relative;
    top: -8px;
    margin-top: auto;
    a {
      color: $color-white;
    }
  }
}

.scrollOverflow {
  position: absolute;
  height: 10px;
  width: 100%;
  top: -10px;
  background: red;
  box-shadow: 0 8px 15px -2px grey;
  opacity: 0.5;
}
