.mapKey {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4;
}

.mapKeyOverlay {
  z-index: 9;

  .keyPanel {
    width: 95%;
    margin: 0 auto;
    margin-top: 24px;
    min-height: 50%;
  }
  .keyPanel__close {
    position: relative;
    top: 0;
    right: 0px;
  }

  .keyPanel__description {
    padding: 0;
    margin-top: 24px;
  }
}

.mapKeyTrigger {
  @include buttonReset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 6;
  border-radius: 100%;

  background: $color-blue;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 24px;
  top: 24px;
  &:hover {
    background: $color-basic-button-hover;
  }
}

.keyPanel {
  background: #f5f2ed;
  width: 333px;
  margin-top: 24px;
  border: 1px solid #cccccc;
}

.keyPanel__content {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.keyPanel__description {
  padding-right: 24px;

  a {
    color: $color-blue;
  }
  p {
    @include ui-text;
    color: $color-text;
  }
}

.keyPanel__key {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #cccccc;
  h3 {
    color: $color-text;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  .keyColor {
    background: $color-red;
    height: 3px;
    width: 18px;
    margin-right: 4px;
    position: relative;
    top: -2px;
  }

  li {
    margin-top: 24px;
    display: flex;
    align-items: center;
    width: 50%;
    color: $color-text;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    span {
      display: block;
    }
  }
}

.keyPanel__close {
  position: absolute;
  right: 24px;
  top: 28px;
  @include buttonReset;

  margin-left: auto;
  path {
    fill: $color-text;
  }
  &:hover {
    opacity: 0.7;
  }
}
