// Baseline grid height
$base-height: 5;

// Max widths the main column can run to over the breakpoints
// values can either be 'fluid' or a pixel value
// recommended xxlarge is a px value and xsmall is fluid
$main-col-widths: (
  // xxxlarge: 1680px,
    // xxlarge: 'fluid',
    xlarge: 1280px,
  large: 'fluid',
  medium: 'fluid',
  small: 'fluid',
  xsmall: 'fluid'
);

// Inner gutters, in px or vw, of each breakpoint
$inner-gutters: (
  // xxxlarge: 40px,
    // xxlarge: 40px,
    xlarge: 40px,
  large: 26px,
  medium: 16px,
  small: 16px,
  xsmall: 16px
);

// Outer gutters, in px or vw, of each breakpoint
$outer-gutters: (
  // xxxlarge: 40px,
    // xxlarge: 40px,
    xlarge: 40px,
  large: 26px,
  medium: 16px,
  small: 16px,
  xsmall: 16px
);

// How many columns are in each breakpoint
$column-count: (
  // xxxlarge: 12,
    // xxlarge: 12,
    xlarge: 12,
  large: 12,
  medium: 12,
  small: 12,
  xsmall: 12
);

// Breakpoint information, where each starts and stops
// if a breakpoint is not fluid, then the start value is equal to the main col value plus 2x the gutter at this breakpoint
$breakpoints: (
  xsmall: (
    start: null,
    end: 543,
  ),
  small: (
    start: 544,
    end: 767,
  ),
  medium: (
    start: 768,
    end: 1023,
  ),
  large: (
    start: 1024,
    end: 1379,
  ),
  xlarge: (
    start: 1380,
    end: null,
  ),
  // xxlarge: (
    //     start: 1520,
    //     end: null,
    //   ),
    // xxxlarge: (
    //   start: 2000,
    //   end: null,
    // ),,,,,,,,,,,,
);

// Uniform border radius
$border-radius: 2px;

// Some easing functions
$bezier--bounce: cubic-bezier(0.5, -0.6, 0.5, 1.6);
$bezier--load: cubic-bezier(0, 0, 0.22, 1.32);
$bezier--unload: cubic-bezier(0.61, -0.38, 1, 1);
$bezier--ease-in-out: cubic-bezier(0.34, 0.01, 0.56, 1);
