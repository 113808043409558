@mixin buttonReset() {
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
