// @font-face {
//   font-family: 'Mercury Display';
//   src: url('./../../fonts/mercury/MercuryDisplay-Roman_Web.woff2')
//       format('woff2'),
//     url('./../../fonts/mercury/MercuryDisplay-Roman_Web.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Mercury Display';
//   src: url('./../../fonts/mercury/MercuryDisplay-Italic_Web.woff2')
//       format('woff2'),
//     url('./../../fonts/mercury/MercuryDisplay-Italic_Web.woff') format('woff');
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Mercury Display';
//   src: url('./../../fonts/mercury/MercuryDisplay-Semibold_Web.woff2')
//       format('woff2'),
//     url('./../../fonts/mercury/MercuryDisplay-Semibold_Web.woff') format('woff');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Mercury Display';
//   src: url('./../../fonts/mercury/MercuryDisplay-SemIta_Web.woff2')
//       format('woff2'),
//     url('./../../fonts/mercury/MercuryDisplay-SemIta_Web.woff') format('woff');
//   font-weight: 600;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Avenir';
//   src: url('./../../fonts/avenir/AvenirLTPro-Book/font.woff2') format('woff2'),
//     url('./../../fonts/avenir/AvenirLTPro-Book/font.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Avenir';
//   src: url('./../../fonts/avenir/AvenirLTPro-Heavy/font.woff2') format('woff2'),
//     url('./../../fonts/avenir/AvenirLTPro-Heavy/font.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
// }

// body {
//   font-family: 'Mercury Display';
// }
em,
i {
  font-style: italic;
}

u {
  text-decoration: underline;
}

strong {
  font-weight: 700;
}
