.cta {
  @include link;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-flex;
  align-items: center;
  svg {
    transition: all 0.3s;
    margin-left: 8px;
    position: relative;
    top: -1px;
  }

  @include buttonReset;

  &:hover {
    @include hover-supported {
      color: $color-blue-dark;
      path {
        fill: $color-blue-dark;
      }
    }
  }
}

.tooltip {
  .cta {
    color: $color-white;
    transition: 0.3s all;
    svg {
      path {
        fill: $color-orange;
      }
    }
    &:hover {
      @include hover-supported() {
        color: $color-white;
        svg {
          path {
            fill: $color-orange;
          }
        }
        transform: translateX(10px);
      }
    }
  }
}
