.filterView__section {
  &:first-of-type {
    margin-top: 24px;
  }
  padding: 24px 0;
  border-bottom: 1px solid $color-border;
  &:first-child {
    padding-top: 0;
  }
  h3 {
    @include ui-04;
    font-weight: bold;
  }
}

.filterView__section--noTitle {
  .checkbox:first-of-type {
    margin-top: 0;
  }
}
