////////////////////////////////////////////////////////////////////////////////////
// hotspot text
@mixin ui-02 {
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
}
.iu-02 {
  @include ui-02;
}
////////////////////////////////////////////////////////////////////////////////////
// modal info
@mixin ui-03 {
  font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
}

////////////////////////////////////////////////////////////////////////////////////
// modal attr
// header area
@mixin ui-04 {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

////////////////////////////////////////////////////////////////////////////////////
// option text
@mixin ui-08 {
  font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 140%;
  letter-spacing: -0.02em;
}

////////////////////////////////////////////////////////////
// specfici interactive-2 styles
////////////////////////////////////////////////////////////

// text
@mixin ui-text {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

@mixin ui-uitext {
  font-family: 'Avenir';
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.01em;
}

@mixin ui-yearTitle {
  font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 150%;
}

@mixin ui-modaltext {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
}

@mixin ui-detailMap {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

@mixin ui-checkbox-label {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
}
