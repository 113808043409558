.checkbox {
  margin-top: 20px;
  display: block;
  cursor: pointer !important;
  display: flex;
}

.checkbox input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: calc(18px + 12px);
}

.checkbox label:hover {
  .checkbox__label {
    color: $color-blue;
  }
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: '';

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before {
  height: 18px;
  width: 18px;
  background: $color-white;
  border: 1px solid $color-border;
  left: 0px;

  top: 1px;
}

.checkbox__label {
  position: relative;
  top: 1px;
  @include ui-checkbox-label;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  cursor: pointer;
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-color: $color-white;
  transform: rotate(-45deg);
  left: 4px;
  top: 6px;
}

/*Hide the checkmark by default*/
.checkbox input[type='checkbox'] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

.checkbox input[type='checkbox']:checked + label::before {
  background: $color-blue;
  border: 1px solid $color-blue;
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.user-is-tabbing {
  .checkbox input[type='checkbox']:focus + label::before {
    outline: solid;
    // outline: rgb(59, 153, 252) auto 5px;
  }
}
