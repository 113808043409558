////////////////////////////////////////////////////////////
//
// map layout
//
////////////////////////////////////////////////////////////

$controlsFull: 330px;
$controlsClosed: 83px;
$mapFull: 100%;
$mobleControls: 90vw;
$mapFull--expanded: calc(100% - #{$controlsFull});
$mapFull--closed: calc(100% - #{$controlsClosed});

.mapControls {
  width: $controlsFull;
  height: 100%;
  z-index: 7;
  @include breakpoint('large-') {
    @include width(4, 'large');
  }

  @include breakpoint('medium-') {
    width: $mobleControls;

    left: 0;
  }
}
.mapComponent {
  right: 0;
  transition: all 0.4s;
  position: absolute;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: $mapFull--expanded;
  @include breakpoint('large-') {
    right: 0;
    width: calc(100% - #{colspan(4, 'large')} - 25px); // controls at large size
  }

  @include breakpoint('medium-') {
    width: $mapFull--closed;
  }
}

.mapExpand {
  .mapControls__expandTrigger {
    svg {
      position: relative;
      left: -7px;

      transform: rotate(0deg);
    }
  }
  .mapControls {
    transform: translateX(calc(-100% + 83px));
    @include breakpoint('large-') {
      transform: translateX(calc(-100% + 83px - 26px));
    }
    @include breakpoint('medium-') {
      transform: translateX(calc(-100% + 83px - 16px));
    }
  }
  .mapComponent {
    width: $mapFull--closed;
  }
}

////////////////////////////////////////////////////////////
//
// map layout
//
////////////////////////////////////////////////////////////

.page--main {
  background: #f5f2ed;
  display: block;

  .page__content {
    width: 100%;

    display: block;
    height: 100%;
  }
  main {
    @extend %container;
    @include breakpoint('xlarge') {
      width: 100%;
    }
    background: #eee;
    display: flex;
    flex-wrap: wrap;
  }

  .page__content {
    display: flex;
  }
  .mapComponent {
    background: #eee;
  }
  .mapComponent__panNode {
    position: absolute;

    .mapHotspot {
      position: absolute !important;
    }
  }
}

.mapComponent {
  .hotspot {
    opacity: 0;
  }
}

.mapComponent__map {
  display: block;
}

.mapComponent__controls {
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 9;
  div {
    margin-top: 2px;
  }

  @include breakpoint('medium-') {
    display: none;
  }
}

// .secret {
//   height: 1px;
//   width: 1px;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   opacity: 0;
// }

// not being used currently
// .elemPointer {
//   z-index: 9;
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 20px;
//   width: 20px;
//   color: black;
// }
