.tutorialOverlay {
  color: $color-white;
  position: absolute;
  z-index: 5;
  background: rgba(0, 0, 0, 0.55);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: flex-end;
  padding-bottom: $spacing-06;
  p {
    @include ui-text;
    margin-top: $spacing-07;
  }
}

.mapOverlayMobile {
  position: absolute;
  z-index: 5;
  background: rgba(0, 0, 0, 0.55);
  height: 100%;
  width: 100%;
  opacity: 0;
  display: none;
}
